import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import image from "../assets/images/random-image.jpg";
import { Link } from "react-router-dom";

export default function PaymentStatus() {
  const [loading, setLoading] = useState(false);
  const [purchaseData, setPurchaseData] = useState();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "purchaseDetails");
    formData.append("purchase_number", params.purchase_number);
    axios
      .post(process.env.REACT_APP_URL, formData)
      .then((res) => {
        console.log(res.data);
        setPurchaseData(res.data.data.purchase_data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div className="bg-[black] sm:rounded-[20px] max-w-2xl my-[4rem] mx-auto">
          <div
            role="status"
            class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
          >
            <div class="w-full">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 m-auto flex"></div>
              <div class="flex justify-center gap-[1rem]">
                <div class="flex items-center justify-center w-full w-[150px] h-[130px] bg-gray-300 rounded dark:bg-gray-700 mb-4"></div>
                <div class="flex items-center justify-center w-full w-[150px] h-[130px] bg-gray-300 rounded dark:bg-gray-700 mb-4"></div>
              </div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] m-auto mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] m-auto mb-2.5"></div>
              <div class="h-8 bg-gray-200 rounded-[7px] dark:bg-gray-700 w-[450px] m-auto"></div>
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="bg-[black] sm:rounded-[20px] max-w-2xl my-[4rem] mx-auto">
          <div className="h-full w-full p-[0.1rem]">
            <div className="text-white text-center text-[26px] text-bold mt-[2rem]">
              {purchaseData?.status === "1"
                ? "Payment Confirmation"
                : purchaseData?.status === "2"
                ? "Payment Failed"
                : "Payment Pending"}
            </div>
            <div className="text-[#808080] text-center text-[18px] mt-[1rem]">
              {purchaseData?.status === "1"
                ? "Thank you for your registration."
                : purchaseData?.status === "2"
                ? "We are unable to process your payment."
                : "Processing your payment,"}
              <br />
              {purchaseData?.status === "1"
                ? "We look forward to meeting with you at the Investor’s Lounge."
                : purchaseData?.status === "2"
                ? "Please try again or contact support"
                : "Please refresh after a movement or contact support"}
            </div>
           { /* <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row px-[3rem] sm:px-[2rem] md:px-[7rem] mt-[2rem] justify-center items-center w-full">
              <img
                src={image}
                className="w-[250px] h-[130px] object-contain p-2.5"
              />
              <div className="bg-[#282828] w-[250px] h-[130px] ">
                <div className="m-[2rem]">
                  <div className="text-white text-bold">
                    Character Design for Animation
                  </div>
                  <div className="text-[#808080]">12 - 19 Aug, 2021</div>
                </div>
              </div>
            </div>

      */ }


            {purchaseData?.status === "2" && (
              <div className="mx-[7rem]">
                <Link
                  to="/"
                  className="text-white bg-[#FF0000] w-full rounded-[7px] py-[.7rem] my-[2rem] flex justify-center items-center gap-[.5rem]"
                >
                  <span>Retry payment</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
