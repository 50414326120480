import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import New from "./pages/New";
import PaymentForm from "./pages/PaymentForm";
import PaymentStatus from "./pages/PaymentStatus";

function App() {
  return (
    <Router>
      <div className="flex-1">
        <Routes>
          <Route path="/" element={<PaymentForm />} />
          <Route path="/v" element={<PaymentForm />} />

       

          <Route
            path="/payment-status/:purchase_number"
            element={<PaymentStatus />}
          />
          <Route path="/new" element={<New />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
