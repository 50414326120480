import { useState } from "react";
import axios from "axios";
import image from "../assets/images/random-image.jpg";

export default function PaymentForm() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    nationality: "",
    designation: "",
    company: "",
  });

  const nationality = [
    "Select Country",
    "United Arab Emirates",
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia, Plurinational State of",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, the former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, Bolivarian Republic of",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const handleInputChange = (input) => {
    setData((prev) => {
      return {
        ...prev,
        [input.name]: input.value,
      };
    });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "createBooking");
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    axios
      .post(process.env.REACT_APP_URL, formData)
      .then((res) => {
        console.log(res);
        if (res.data.success) initiatePayment(res.data.purchase_number);
        else setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const initiatePayment = (purchase_number) => {
    const formData = new FormData();
    formData.append("action", "initiatePayment");
    formData.append("purchase_number", purchase_number);
    formData.append(
      "redirect_to",
      `${window.location.origin}/payment-status/${purchase_number}`
    );
    axios
      .post(process.env.REACT_APP_URL, formData)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) window.location.href = res.data.payment_link;
        else setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="bg-[white] max-w-2xl my-auto md:my-12 mx-auto py-12   rounded-xl	 	 ">
      <div className="h-full w-full p-[0.1rem]">

      <section class="hero container max-w-screen-lg mx-auto pb-10">

        <img
          class="inset-0 w-[250px] md:h-auto m-auto object-contain p-2.5 rounded-3xl"
          src="https://i.postimg.cc/8PSdShfN/FDI-new-logo.png"
        />
</section>

 <img
          class="inset-0 w-full  md:h-[300px] h-auto mt-4 object-contain p-2.5 rounded-xl"
          src="https://i.postimg.cc/8kYdpGVV/2024.jpg"
        />

        <div className="hidden text-center text-[26px] text-bold">
          Sharjah Investment Forum - Investor Lounge Registration
        </div>
        <div className="text-[#808080] text-center text-[16px] mt-[1rem] p-2.5">
        
        <p>Building on last year's success, the Investors’ Lounge returns for its 2nd edition as a world-class networking hub, dedicated to strengthening strategic partnerships, exploring investment trends, discovering potential collaborations, and discussing global economic trends.
        </p>       
        
        <p>
          Join us to learn why Sharjah remains the premier destination for global investors seeking growth and innovation.
        </p>
        
        </div>
       
        {/* <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row px-[3rem] sm:px-[2rem] md:px-[7rem] mt-[2rem] justify-center items-center w-full">
          <img src={image} className="w-[250px] h-[130px] object-cover" />
          <div className="bg-[#282828] w-[250px] h-[130px]">
            <div className="m-[2rem]">
              <div className="text-white text-bold">
                Character Design for Animation
              </div>
              <div className="text-[#808080]">12 - 19 Aug, 2021</div>
            </div>
          </div>
        </div> */}
        <form onSubmit={handleSubmitData}>
          <div className="flex flex-col gap-[1rem] mt-[3rem] px-[2rem] sm:px-[2rem] md:px-[7rem] lg:px-[7rem] m-auto">
            <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row gap-[1rem] w-full">
              <div className="relative">
                <input
                  id="first_name"
                  type="text"
                  className="peer px-[24px] w-full py-[16px] rounded-[7px] text-black me-[12px] bg-[#f7f7f7cf] border border-[#c1c1c1] focus:outline-none"
                  name="first_name"
                  placeholder=" "
                  required
                  value={data.first_name}
                  onChange={(e) => handleInputChange(e.target)}
                />
                <label
                  for="first_name"
                  className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0]  bg-[#f7f7f7cf]  px-4 peer-focus:px-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                  First Name
                </label>
              </div>
              <div className="relative">
                <input
                  id="last_name"
                  type="text"
                  className="peer px-[24px] w-full py-[16px] rounded-[7px] text-black me-[12px] bg-[#f7f7f7cf] border border-[#c1c1c1] focus:outline-none"
                  name="last_name"
                  placeholder=" "
                  required
                  value={data.last_name}
                  onChange={(e) => handleInputChange(e.target)}
                />
                <label
                  for="last_name"
                  className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0]  bg-[#f7f7f7cf]  px-4 peer-focus:px-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                  Last Name
                </label>
              </div>
            </div>
            <div className="relative">
              <input
                id="email"
                type="email"
                className="peer px-[24px] w-full py-[16px] rounded-[7px] text-black me-[12px] bg-[#f7f7f7cf] border border-[#c1c1c1] focus:outline-none"
                placeholder=" "
                name="email"
                required
                value={data.email}
                onChange={(e) => handleInputChange(e.target)}
              />
              <label
                for="email"
                className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0]  bg-[#f7f7f7cf]  px-4 peer-focus:px-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Email
              </label>
            </div>
            <div className="relative">
              <input
                id="phone"
                type="number"
                className="peer px-[24px] w-full py-[16px] rounded-[7px] text-black me-[12px] bg-[#f7f7f7cf] border border-[#c1c1c1] focus:outline-none"
                placeholder=" "
                name="phone"
                required
                value={data.phone}
                onChange={(e) => handleInputChange(e.target)}
              />
              <label
                for="phone"
                className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0]  bg-[#f7f7f7cf]  px-4 peer-focus:px-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Phone
              </label>
            </div>
            <select
              type="text"
              className="px-[24px] w-full py-[16px] rounded-[7px] text-black me-[12px] bg-[#f7f7f7cf] border border-[#c1c1c1] focus:outline-none"
              name="nationality"
              required
              value={data.nationality}
              onChange={(e) => handleInputChange(e.target)}
            >
              {nationality.map((value) => (
                <option value={value === "Select Country" ? "" : value}>
                  {value}
                </option>
              ))}
            </select>
            <div className="relative">
              <input
                id="designation"
                type="text"
                className="peer px-[24px] w-full py-[16px] rounded-[7px] text-black me-[12px] bg-[#f7f7f7cf] border border-[#c1c1c1] focus:outline-none"
                placeholder=" "
                name="designation"
                required
                value={data.designation}
                onChange={(e) => handleInputChange(e.target)}
              />
              <label
                for="designation"
                className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0]  bg-[#f7f7f7cf]  px-4 peer-focus:px-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Designation
              </label>
            </div>
            <div className="relative">
              <input
                id="company"
                type="text"
                className="peer px-[24px] w-full py-[16px] rounded-[7px] text-black me-[12px] bg-[#f7f7f7cf] border border-[#c1c1c1] focus:outline-none"
                placeholder=" "
                name="company"
                required
                value={data.company}
                onChange={(e) => handleInputChange(e.target)}
              />
              <label
                for="company"
                className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0]  bg-[#f7f7f7cf]  px-4 peer-focus:px-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Company
              </label>
            </div>
            <div className="w-full">
              <button
                type="submit"
                className="text-white bg-[#0f6a6c] w-full rounded-[7px] py-[.7rem] mt-[1rem] flex justify-center items-center gap-[.5rem]"
                disabled={loading}
              >
                {loading && (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
                <span>
                  {loading ? "Submitting Details...." : "Register (AED 1000)"}
                </span>
              </button>
            </div>

            {/*  
           
           <div className="text-[#808080] w-full text-white text-center">
              Signing up means you're okay with our{" "}
              <span className="text-[#005ce6] cursor-pointer">
                terms and conditions
              </span>
            </div>
            <div className="text-[#808080] w-full text-white text-center my-[2rem]">
              Have an account?{" "}
              <span className="text-[#005ce6] cursor-pointer">Log in</span>
            </div>

                */}
          </div>
        </form>
      </div>
    </div>
  );
}
