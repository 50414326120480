export default function Example() {
  return (
    <div className="relative bg-gray-50">
      <main className="lg:relative">
        <div className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
            alt=""
          />
        </div>
        <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-24 lg:right-0 lg:text-left">
          <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Sharjah Investment Forum</span>{" "}
              <span className="block text-indigo-600 xl:inline">
                Investor Lounge Access
              </span>
            </h1>
            <p className="mx-auto mt-3 max-w-md text-[17px] text-gray-500 md:mt-5 md:max-w-3xl">
              Sharjah Investment Forum is introducing the “Investor's Lounge” at
              its 6th edition this year. This exclusive space is available to
              high profile individuals including Government officials, CEOs,
              VPs, Directors, Venture Capital Investors, Visionaries,
              Entrepreneurs, Business Professionals and like-minded people with
              a strong desire to contribute to the development of the economy in
              UAE. The lounge offers a unique platform for attendees to make
              valuable connections and further their business goals.
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
                >
                  Get started
                </a>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  href="#"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50 md:py-4 md:px-10 md:text-lg"
                >
                  Live demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
